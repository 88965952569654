import { Controller } from "@hotwired/stimulus"
import InfiniteScroll from 'infinite-scroll'

export default class extends Controller {
    static get targets() {
        return ["next", "grid", "footer"]
    }

    connect() {
        let infScroll;
        let el = this;

        if (this.hasNextTarget) {
            infScroll = new InfiniteScroll(this.gridTarget, {
                path: `#${this.element.id} .next_page a`,
                append: `#${this.element.id} .infinite-scroll-element`,
                scrollThreshold: false,
                status: `#${this.element.id} .page-load-status`,
                button: `#${this.element.id} .view-more-button`,
                history: false
            })

            this.footerTarget.querySelector('.view-more-button').style.display = 'inline-flex'

            // When new content is appended, re-layout the gallery to ensure new photos position correctly
            if (infScroll) {
                // Dispatch
                infScroll.on('append', (event, response, path, items) => {
                    // note: the 'event' here is the jQuery event, the dispatch below will also dispatch with its own event
                    // passing the original jQuery event (which is not strictly a DOM event) in the detail as it may be used
                    const detail = { event, response, path, items };

                    this.dispatch('append', {
                        cancelable: false,
                        detail,
                        target: event.target,
                    });
                })
            }
        } else {
            this.footerTarget.querySelector('.view-more-button').style.display = 'none'
        }
    }
}
